<template>
  <div>

    <v-list
      color="transparent"
    >
      <p class="mx-5 mt-3 mb-5 font-weight-medium">{{ $t('onboarding.investmentSurvey.views.intro.intro')}}</p>
      <ul>
        <v-list-item
          v-for="product in products"
          :key="product"
          class="ml-3"
        >
          <li>
            {{ $t(`onboarding.investmentSurvey.products.${product}`) }}
          </li>
        </v-list-item>
      </ul>

    </v-list>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'InvestmentSurveyIntro',
  computed: {
    ...mapGetters('onboarding', [
      'products',
    ]),
  },
  created() {
    this.setFormValidity(true);
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
  },
};
</script>

<style lang="scss" scoped>

</style>
